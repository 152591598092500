import styled from 'styled-components';
import bgImageMobile from 'img/bgr_waves_mobile.svg';
import bgImage from 'img/bgr_waves.svg';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'View/Theme';

export default styled.main`
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: ${colors.sleep.blue.c900};
  align-items: center;
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: ${asRem(660)};
  overflow: hidden;

  .mime-elements-section {
    .container {
      max-width: ${asRem(944)};
    }

    &[type='full-width'] .container {
      max-width: 100%;
    }
  }

  ${mediaQueries.md} {
    background-image: url(${bgImage});
  }

  .regular-font p {
    font-family: 'Source Sans Pro';
    font-weight: 400;
  }

  h2 {
    text-align: center;
    padding-left: ${asRem(96)};
    padding-right: ${asRem(96)};

    ${mediaQueries.sm} {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .caption {
    .error {
      display: inline-block;
      color: ${colors.sleep.red.c500};
    }
  }

  .stars-background {
    padding-bottom: 0;
  }

  .header-section {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: ${asRem(100)};
    min-height: auto;
    padding-bottom: ${asRem(24)};

    ${mediaQueries.md} {
      padding-bottom: 0;
      min-height: ${asRem(640)};
      padding-top: ${asRem(120)};
    }

    ${mediaQueries.lg} {
      padding-bottom: 0;
      min-height: ${asRem(740)};
      padding-top: ${asRem(150)};
    }

    .wrapper {
      flex-direction: column;
      justify-content: center;

      ${mediaQueries.md} {
        flex-direction: row;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      ${mediaQueries.xl} {
        margin-right: -${asRem(80)};
      }

      h1 {
        white-space: normal;

        ${mediaQueries.xl} {
          white-space: nowrap;
        }
      }

      .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 ${asRem(24)};
      }
    }

    .sleeping-luna-image {
      width: 100%;
      margin-left: ${asRem(20)};

      ${mediaQueries.md} {
        width: 120%;
        margin-left: -${asRem(40)};
      }
      ${mediaQueries.lg} {
        width: 140%;
        margin-left: -${asRem(120)};
      }
    }
  }

  section {
    position: relative;

    .app-section-background {
      position: absolute;
      bottom: -${asRem(40)};
      left: -${asRem(60)};
      width: 120%;
      z-index: -1;

      ${mediaQueries.md} {
        bottom: -${asRem(48)};
        left: -${asRem(40)};
        width: 65%;
      }
    }
  }

  .expert-section {
    padding-bottom: 0;

    .expert-image {
      padding: ${asRem(64)} ${asRem(32)} 0 ${asRem(32)};
      margin-bottom: ${asRem(12)};

      ${mediaQueries.sm} {
        padding: 0;
        margin-bottom: ${asRem(32)};
      }
    }

    .content-area {
      position: static;
    }

    h2 {
      position: absolute;
      top: ${asRem(38)};
      padding: 0 ${asRem(72)};
      text-align: center;
      left: 0;
      right: 0;

      ${mediaQueries.sm} {
        position: static;
        left: auto;
        right: auto;
        text-align: left;
        padding: 0;
        top: auto;
      }
    }
  }

  .refund-check-form-section {
    padding-top: ${asRem(40)};
    padding-bottom: ${asRem(128)};
    margin-bottom: ${asRem(16)};

    ${mediaQueries.md} {
      margin-bottom: ${asRem(30)};
      padding-top: ${asRem(72)};
      padding-bottom: ${asRem(90)};
    }

    h2 {
      padding-left: ${asRem(36)};
      padding-right: ${asRem(36)};

      ${mediaQueries.sm} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .app-section-background {
      bottom: -2rem;
      left: -${asRem(5)};
      width: 100%;
    }

    .caption.info-text {
      line-height: 1;
    }

    .price-area .flex {
      display: flex;
      justify-content: end;
      width: 100%;
    }

    .content-form-box {
      align-self: flex-end;
      width: 100%;
      max-width: 100%;
      position: relative;
      margin-top: ${asRem(24)};

      ${mediaQueries.md} {
        margin-top: 0;
        max-width: ${asRem(340)};
      }
    }

    .price-field {
      font-size: ${asRem(44)};
      margin-bottom: ${asRem(20)};
      font-weight: 400;
    }

    .buy-button {
      padding: 0 ${asRem(35)};
    }
  }

  .how-to-section {
    padding-bottom: ${asRem(72)};

    ${mediaQueries.sm} {
      padding-bottom: ${asRem(156)};
    }

    ${mediaQueries.md} {
      padding-bottom: ${asRem(190)};
    }

    h2 {
      padding-left: ${asRem(36)};
      padding-right: ${asRem(36)};

      ${mediaQueries.sm} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .container {
      width: 100%;
    }

    .fact-table {
      margin-bottom: ${asRem(32)};

      ${mediaQueries.md} {
        margin-bottom: ${asRem(48)};
      }
    }

    .app-anker-background {
      position: absolute;
      bottom: -${asRem(36)};
      min-width: ${asRem(375)};
      width: 100%;
      right: 0;
      z-index: -1;

      ${mediaQueries.md} {
        position: absolute;
        bottom: -${asRem(5)};
        width: ${asRem(840)};
        right: 5%;
      }
    }
  }

  .app-description-section {
    padding-top: ${asRem(36)};
    padding-bottom: 0;
    justify-content: center;
    align-items: center;

    ${mediaQueries.md} {
      padding-top: ${asRem(72)};
      padding-bottom: ${asRem(20)};
    }

    .phone {
      ${mediaQueries.md} {
        width: 105%;
      }
      ${mediaQueries.lg} {
        width: 140%;
      }
    }

    h2 {
      padding-left: ${asRem(12)};
      padding-right: ${asRem(12)};

      ${mediaQueries.md} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .text-left {
      text-align: center !important;
      z-index: 5;
      ${mediaQueries.md} {
        text-align: left !important;
      }
    }

    .app-section-background-plane {
      margin-left: ${asRem(-120)};
      width: 140%;
      z-index: -5;

      ${mediaQueries.md} {
        margin-top: -${asRem(160)};
        margin-left: ${asRem(-170)};
        width: 90%;
      }
    }
  }

  ${mediaQueries.lg} {
    .expert-section {
      padding-bottom: ${asRem(16)};
    }

    .refund-check-form-section {
      .form-area {
        margin-bottom: ${asRem(20)};
      }
      .content-form-box {
        max-width: 100%;
      }
    }
  }

  .crossed-out-price {
    text-decoration: line-through;
    color: ${colors.sleep.red.c300};
  }

  .buy-button {
    white-space: normal;
    padding: ${asRem(2)} ${asRem(10)};
  }
`;
