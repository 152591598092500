import { Link } from 'View/Common';
import React, { useState, MouseEvent } from 'react';
import { HealthRequirementsModal } from 'View/Component/Modals';

export const HealthPreConditionsLabel = () => {
  const [showHealthRequirements, setShowHealthRequirements] = useState(false);

  const handleOnClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    setShowHealthRequirements(true);
  };

  return (
    <>
      <HealthRequirementsModal isOpen={showHealthRequirements} toggle={() => setShowHealthRequirements(false)} />
      Ich akzeptiere die{' '}
      <Link title="öffnet ein Fenster mit dem gesundheitlichen Voraussetzungen" className="form-regulatory-link" onClick={handleOnClick}>
        Gesundheitsvoraussetzungen
      </Link>{' '}
      und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.
    </>
  );
};
