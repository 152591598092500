import styled from 'styled-components';
import { Switch } from 'antd';
import { asRem } from 'utils/asRem';
import { colors } from 'View/Theme';

export default styled.div`
  display: inline-block;
  height: ${asRem(40)};
  padding: ${asRem(10)} ${asRem(16)};
  border-radius: ${asRem(20)} ${asRem(20)} ${asRem(20)} 0;
  background-color: ${colors.sleep.blue.c300};
  color: ${colors.sleep.beige.c100};
  font-family: 'Source Sans Pro';
  font-size: ${asRem(16)};
  margin-bottom: ${asRem(20)};
  font-weight: 600;
`;
