export const COOPERATION_VOUCHER = {
  AOK_BAYERN: 'AOK_BAYERN',
  AOK_NORDOST: 'AOK_NORDOST',
  SALUS_BKK: 'SALUS_BKK',
  VIACTIV: 'VIACTIV',
  VIVIDA_BKK: 'VIVIDA_BKK',
  IKK_CLASSIC: 'IKK_CLASSIC',
} as const;

export type TCooperationVoucherConfig = Record<
  keyof typeof COOPERATION_VOUCHER | 'FALLBACK',
  {
    staging: string;
    production: string;
  }
>;

export const COOPERATION_VOUCHER_CONFIG: TCooperationVoucherConfig = {
  [COOPERATION_VOUCHER.AOK_BAYERN]: {
    staging: 'b4e318d1-c76d-4fde-8e1f-4aaa2146864a',
    production: '921c8f8a-416b-490e-b9a3-18b61c10c8d4',
  },
  [COOPERATION_VOUCHER.AOK_NORDOST]: {
    staging: '2485df2b-638e-4ce3-a6ff-d055d39a553f',
    production: 'e25edb99-1368-438b-8122-6b39f7f3d02a',
  },
  [COOPERATION_VOUCHER.SALUS_BKK]: {
    staging: '9c6bf34c-38e0-4c1d-86e2-6494dd54538f',
    production: '10dcb816-6586-4ea7-bfac-d5327c31008a',
  },
  [COOPERATION_VOUCHER.VIACTIV]: {
    staging: '57ce209a-5a35-4175-981c-9a6260f665d4',
    production: '16d56459-d2a2-48d9-978a-0688c7b45913',
  },
  [COOPERATION_VOUCHER.VIVIDA_BKK]: {
    staging: '53adb93a-8583-427b-891f-4176db1e20fe',
    production: '72cd49d9-7061-4ffe-a880-011a530bd49a',
  },
  [COOPERATION_VOUCHER.IKK_CLASSIC]: {
    staging: 'e79093bb-a248-4239-837b-611b1a01dde2',
    production: '10d1a32a-15cb-4ce8-89fd-b7fab004bf6f',
  },
  FALLBACK: {
    staging: 'fallback-staging',
    production: 'fallback-production',
  },
};
