import React, { FunctionComponent } from 'react';
import { Col, Row } from 'View/Common';
import { OfferBox, ZppOfferBox } from 'View/Component';
import { SinglePaymentOffer, SubscriptionOffer } from 'api/graphql';

interface IProps {
  offers: {
    zpp: SubscriptionOffer | null;
    subscriptions: (SinglePaymentOffer | SubscriptionOffer)[];
  };
}

export const ZppOffers: FunctionComponent<IProps> = ({ offers }) => {
  return (
    <Row className="offer-wrapper">
      <Col sm={12} md={6} className="pr-0 p-md-0">
        <ZppOfferBox offer={offers.zpp as SubscriptionOffer} />
      </Col>
      <Col sm={12} md={6} className={'pr-0'}>
        {offers.subscriptions
          .slice()
          .sort((a, b) => a.position - b.position)
          .map((offer) => (
            <OfferBox key={offer.uuid} offer={offer as SinglePaymentOffer | SubscriptionOffer} />
          ))}
      </Col>
    </Row>
  );
};
