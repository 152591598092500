import React, { FunctionComponent, useState } from 'react';
import { BgStars, HealthBenefitsModal } from 'View/Component';
import { Col, Headline, Image, Paragraph, Row, Button } from 'View/Common';
import ZppSleepingLuna from 'img/ZppSleepingLuna';
import { Section } from 'View/Container';

interface IProps {
  startNowButton?: () => void;
}

export const HeaderSection: FunctionComponent<IProps> = ({ startNowButton }) => {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const toggleModal = (visible: boolean) => () => {
    setModalIsVisible(visible);
  };

  return (
    <BgStars className="stars-background">
      <HealthBenefitsModal isOpen={modalIsVisible} toggle={toggleModal(false)} />
      <Section className="header-section">
        <Row className="wrapper">
          <Col>
            <Image
              className="sleeping-luna-image"
              image={ZppSleepingLuna.src}
              srcSet={ZppSleepingLuna.srcSet}
              alt="Luna schlafend mit Erstattungscheck in der Hand"
            />
          </Col>
          <Col className="content-wrapper">
            <Headline level={1}>Erholsamen Schlaf finden</Headline>
            <Paragraph className="margin-bottom-48">Ein App-gestützter Online-Kurs</Paragraph>
            <div className="button-wrapper">
              <Button title="Gehe zum Registrierformular" onClick={startNowButton}>
                Jetzt loslegen
              </Button>
              <Button type="secondary" title="öffnet ein Fenster mit dem gesundheitlichen Nutzen" onClick={toggleModal(true)}>
                Gesundheitlicher nutzen
              </Button>
            </div>
          </Col>
        </Row>
      </Section>
    </BgStars>
  );
};
