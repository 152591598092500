import React, { FunctionComponent } from 'react';
import { BgColorContainer } from 'View/Component';
import { Col, Headline, Paragraph, Row, TextBadge, Image, Button } from 'View/Common';
import ZppAnker from 'img/ZppAnker';
import { Section } from 'View/Container';


interface IProps {
  startNowButton?: () => void;
}

export const HowToSection: FunctionComponent<IProps> = ({ startNowButton }) => {
  return (
    <BgColorContainer style={{ zIndex: -2, paddingBottom: 0 }}>
      <Section className="how-to-section">
        <div className="container text-center">
          <Headline className="text-center" level={2}>
            So funktioniert es: In 4 Schritten zur Erstattung
          </Headline>
          <br />
          <Row className="fact-table">
            <Col xs={12} md={6} lg={3} className="text-left">
              <TextBadge>Schritt 1</TextBadge>
              <Headline level={3}>Registrierung/ Anmeldung</Headline>
              <Paragraph>
                Registriere dich oder melde dich an und hole dir für 75,00 € deinen 7Schläfer-Zugang zu dem Kurs „Erholsamen Schlaf finden –
                ein App-gestützter Online-Kurs“. Ein 12-monatiger Zugang zur gesamten 7Schläfer-App ist darin ebenfalls enthalten.
              </Paragraph>
            </Col>
            <Col xs={12} md={6} lg={3} className="text-left">
              <TextBadge>Schritt 2</TextBadge>
              <Headline level={3}>Kurs absolvieren</Headline>
              <Paragraph>
                Absolviere den Kurs „Erholsamen Schlaf finden – ein App-gestützter Online-Kurs“ in der 7Schläfer-App vollständig, um dir die
                Kosten von deiner Krankenkasse erstatten zu lassen.
              </Paragraph>
            </Col>
            <Col xs={12} md={6} lg={3} className="text-left">
              <TextBadge>Schritt 3</TextBadge>
              <Headline level={3}>Zertifikat erhalten</Headline>
              <Paragraph>
                Wenn du den Kurs erfolgreich innerhalb von 6 Monaten abgeschlossen hast, bekommst du dein Teilnahme-Zertifikat per E-Mail
                zugeschickt. Unser Kundensupport ist bei Fragen oder Problemen gerne für dich da!
              </Paragraph>
            </Col>
            <Col xs={12} md={6} lg={3} className="text-left">
              <TextBadge>Schritt 4</TextBadge>
              <Headline level={3}>Kosten erstatten lassen</Headline>
              <Paragraph>
                Reiche den Zahlungsbeleg mit dem Zertifikat bei der Krankenkasse ein, um deine Erstattung zu erhalten. Beachte, dass die
                Erstattungssumme je nach Krankenkasse unterschiedlich ausfallen kann.
              </Paragraph>
            </Col>
          </Row>
          <Headline level={2} className="text-center">
            Wichtiger Hinweis
          </Headline>
          <Paragraph>
            Bewahre nach dem Kauf den Kaufbeleg auf. Diesen benötigst du später bei deiner Krankenkasse für die Erstattung. Einige Kassen
            beschränken die Anzahl der Erstattungen pro Person auf 1-2 Maßnahmen im Jahr. Nähere Infos dazu erhältst du bei deiner
            Krankenkasse.
          </Paragraph>
          <Paragraph className="margin-bottom-48">
            Nach 12 Monaten läuft dein Zugang automatisch aus, ohne, dass du ihn aktiv kündigen musst. Du kannst bei Gefallen den Kauf des
            Kurses erneut abschließen und dir nochmals von deiner Krankenkasse erstatten lassen.
          </Paragraph>
          <Button title="Gehe zum Registrierformular" onClick={startNowButton}>
            Jetzt loslegen
          </Button>
        </div>
        <Image className="app-anker-background" image={ZppAnker.src} srcSet={ZppAnker.srcSet} alt="Papierboot mit Angelleine" />
      </Section>
    </BgColorContainer>
  );
};
