import React from 'react';
import { IconBox, IIconBoxItem } from 'View/Component';
import { ROUTES } from 'utils/typings/routes';
import { Link } from '@reach/router';
import { MailLink, Span } from 'View/Common';

interface Props {
  data: {
    mainHeadline: string;
    mainSubline: string;
    headline: string;
    items: IIconBoxItem[];
    contactMail: string;
  };
}

const HowToContinueDesktop: React.FC<Props> = ({ data }) => {
  const { mainHeadline, mainSubline, headline, items, contactMail } = data;

  return (
    <>
      <main className="main main--no-footer-cta how-to-continue-desktop">
        <div className="wrapper flex-column padding-top-100 margin-bottom-50">
          <h1 className="heading heading-1 margin-bottom-8">{mainHeadline}</h1>
          <p className="paragraph paragraph__text-center margin-bottom-24">{mainSubline}</p>
          <IconBox headline={headline} items={items} />
          <>
            <p className="paragraph ta-center padding-r-0-100 padding-l-0-100 ">
              Du hast die Freischalt-E-Mail nicht erhalten? Melde dich gerne bei uns unter&nbsp;
              <MailLink mailTo={contactMail} />
              &nbsp;oder probier es nochmal.
            </p>
            <Link className="resend-link" to={ROUTES.RESEND_TOKEN_B2B} title="Neuen Freischalt-Link anfordern">
              Neuen Freischalt-Link anfordern
            </Link>
          </>
        </div>
      </main>
    </>
  );
};

export default HowToContinueDesktop;
