import { setContext } from '@apollo/link-context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { RequestApi } from './RequestApi';

const httpLink = createHttpLink({
  uri: `${
    process.env.BYPASS_PROXY === 'true' ? process.env.API_ORIGIN : process.env.PROXY_ORIGIN ? process.env.PROXY_ORIGIN : ''
  }/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = RequestApi.getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ?? process.env.TOKEN ?? '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});
