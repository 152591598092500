import React, { FC } from 'react';
import { Paragraph, Headline, Link, Span, Button } from 'View/Common';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'Redux/Actions';
import { getInsuranceCooperationErrorCode } from 'Redux/Queries';

export type TErrorViewProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ErrorView: FC<TErrorViewProps> = () => {
  const dispatch = useDispatch();
  const errorCode = useSelector(getInsuranceCooperationErrorCode) || '';

  const handleOnResetForm = () => {
    dispatch(Actions.clearHealthInsuranceUserData());
  };

  const getErrorText = (errorCode: string) => {
    switch (errorCode) {
      case 'ACTIVE_ATTENDANCE_PRESENT':
        return ' Mit deiner Registrierung ist etwas schiefgelaufen. Du hast bereits einen Zugang zum Präventionskurs in der App. Wenn du unsicher bist, kontaktiere unseren Kunden-Support unter:';
      default:
        return 'Mit deiner Registrierung ist etwas schiefgelaufen. Bitte beginne von vorne oder kontaktiere unseren Kunden-Support unter:';
    }
  };

  return (
    <div className="error-view" id="insurance-cooperation-error-view">
      <Headline level={2}>Huch - das tut uns Leid!</Headline>
      <Paragraph className="px-2 px-md-5 ">{getErrorText(errorCode)}</Paragraph>
      <Link href="mailto:info@7schlaefer.app" className="link-paragraph-error">
        <Span>info@7schlaefer.app</Span>
      </Link>
      <Button className="mt-3" onClick={handleOnResetForm}>
        Zurück zur Registrierung
      </Button>
    </div>
  );
};
