import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { DarkContentBox } from 'View/Component';
import { colors } from 'View/Theme';
import { mediaQueries } from 'utils/Constants/Breakpoints';

export default styled(DarkContentBox)`
  position: relative;
  width: ${asRem(340)};
  height: ${asRem(210)};
  padding: ${asRem(26)} ${asRem(16)} ${asRem(16)};
  margin-bottom: ${asRem(16)};
  border-color: ${colors.sleep.blue.c300};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .body {
    margin-top: 0;
    ${mediaQueries.md} {
      margin-top: ${asRem(10)};
      padding-right: ${asRem(16)};
    }
  }

  .offer-label {
    position: absolute;
    top: ${asRem(18)};
    left: 0;
    width: 100%;
    text-align: center;

    ${mediaQueries.md} {
      position: static;
      top: auto;
      left: auto;
      width: auto;
    }
  }

  .box-badge {
    border-top-left-radius: ${asRem(8)};
    border-top-right-radius: ${asRem(8)};
    background-color: ${colors.sleep.blue.c300};
    font-family: 'Source Sans Pro';
    color: ${colors.sleep.beige.c100};
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    letter-spacing: ${asRem(0.3)};
    text-align: center;
    width: 100%;
    height: ${asRem(24)};
    line-height: ${asRem(24)};
  }

  .price-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .crossed-out-price {
      position: absolute;
      left: 0;
      right: 0;
      top: ${asRem(46)};
      font-size: ${asRem(16)};
      color: ${colors.sleep.red.c300};
      text-decoration: line-through;
    }

    .price-label {
      font-family: 'Source Sans Pro';
      height: ${asRem(36)};


      ${mediaQueries.md} {
        margin-bottom: ${asRem(16)};
        margin-top: ${asRem(-8)};
      }
    }

    .price-info-box {
      flex: 1;
      margin: 0 0 ${asRem(4)};

      ${mediaQueries.md} {
        margin-top: ${asRem(8)};
        margin-bottom: 0;
      }

      span {
        display: block;
      }
    }
  }

  .cta-wrapper {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: ${asRem(8)};
      font-size: ${asRem(24)};
    }

    button {
      width: ${asRem(200)};
      padding: ${asRem(0)} ${asRem(40)};
      margin-top: ${asRem(8)};
      margin-bottom: 0;
      min-width: auto;

      ${mediaQueries.md} {
        height: ${asRem(33)};
        line-height: ${asRem(4)};
      }
    }
  }

  ${mediaQueries.md} {
    height: ${asRem(136)};

    &.highlighted {
      height: ${asRem(172)};
      padding-top: ${asRem(46)};
    }

    .cta-wrapper {
      button {
        width: ${asRem(160)};
        padding: ${asRem(15)} ${asRem(28)};
      }
    }

    .price-information {
      text-align: left;

      .crossed-out-price {
          left: ${asRem(18)};
          top: ${asRem(4)};
        }
      }
    }
  }

  &.highlighted {
    padding-top: ${asRem(36)};
    height: ${asRem(250)};

    ${mediaQueries.md} {
      padding-top: ${asRem(36)};
      height: ${asRem(220)};
    }

    .price-information {
      margin-bottom: ${asRem(2)};

      ${mediaQueries.md} {
        top: ${asRem(28)};
      }
    }

    .price-information .crossed-out-price {
      top: ${asRem(65)};

      ${mediaQueries.md} {
        top: ${asRem(32)};
      }
    }

    .price-label {
       padding-top: 0;


      ${mediaQueries.md} {
        padding-top: ${asRem(4)};
      }
    }

    .offer-label {
      top: ${asRem(40)};

      ${mediaQueries.md} {
        top: ${asRem(32)};
      }
    }

    .price-info-box {

    }
  }
`;
