import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';

export default styled.main`
  padding: 0;

  .offer-wrapper {
    max-width: ${asRem(696)};
    display: flex;
    flex-direction: column;
    justify-content: center 
    align-items: center;

    ${mediaQueries.md} {
      flex-direction: row;
      justify-content: space-between !important;
        }
  }
`;
