import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { DarkContentBox } from 'View/Component';
import { colors } from 'View/Theme';

export default styled(DarkContentBox)`
  position: relative;
  width: ${asRem(340)};
  min-height: ${asRem(340)};
  padding: ${asRem(40)} ${asRem(16)} ${asRem(140)};
  margin-bottom: ${asRem(8)};
  border-bottom-color: ${colors.sleep.red.c500};

  .price-label {
    margin-bottom: 0;
    font-family: 'Source Sans Pro';
  }

  .box-badge {
    border-top-left-radius: ${asRem(8)};
    border-top-right-radius: ${asRem(8)};
    background-color: ${colors.sleep.red.c500};
    font-family: 'Source Sans Pro';
    color: ${colors.sleep.beige.c100};
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    letter-spacing: ${asRem(0.3)};
    text-align: center;
    width: 100%;
    height: ${asRem(24)};
    line-height: ${asRem(24)};
  }

  .mime-elements-button {
    width: ${asRem(200)} !important;
    margin-top: ${asRem(16)};
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: auto;
  }

  .sleeping-luna-image {
    bottom: ${asRem(-8)};
    width: ${asRem(150)};
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .crossed-out-price {
    font-size: ${asRem(16)};
    color: ${colors.sleep.red.c300};
    text-decoration: line-through;
    height: ${asRem(24)};
    margin-bottom: 0;
  }

  ${mediaQueries.md} {
    height: ${asRem(478)};
    padding: ${asRem(60)} ${asRem(16)} ${asRem(16)};
    width: ${asRem(340)};

    .price-label {
      margin-bottom: ${asRem(4)};
    }

    .mime-elements-button {
      margin-top: ${asRem(20)};
    }

    .sleeping-luna-image {
      bottom: 0;
      width: ${asRem(220)};
    }
  }
`;
