import React, { FC, ReactNode } from 'react';
import Style from './InsuranceLandingPage.style';
import '@missionme/mime_elements/dist/public/Css/GlobalSleep.css';
import '@missionme/mime_elements/dist/public/Css/GlobalColors.css';
import '@missionme/mime_elements/dist/public/Css/BootstrapUtils.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HeaderSection, THeaderSectionProps } from '../HeaderSection/HeaderSection';
import { CheckMarkSection, TCheckMarkSectionProps } from './CheckMarkSection/CheckMarkSection';
import { SleepBrandLink, Menu } from 'View/Component';

type TProps = {
  children?: ReactNode;
} & {
  HeaderSection?: FC<THeaderSectionProps>;
  CheckMarkSection?: FC<TCheckMarkSectionProps>;
};

export function InsuranceLandingPage({ children }: TProps) {
  return (
    <>
      <Style className="insuranceCooperationLandingPage">
        <Menu brandLink={<SleepBrandLink />} />
        {children}
      </Style>
    </>
  );
}

InsuranceLandingPage.HeaderSection = HeaderSection;
InsuranceLandingPage.CheckMarkSection = CheckMarkSection;
