import styled from 'styled-components';
import { colors } from 'View/Theme';
import { asRem } from 'utils/asRem';

export default styled.div`
  background-color: ${colors.sleep.blue.c700};
  padding: ${asRem(32)} ${asRem(24)};
  border-radius: ${asRem(8)};
  border-bottom: solid ${asRem(4)} ${colors.sleep.blue.c600};
`;
