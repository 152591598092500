import React, { FunctionComponent } from 'react';
import { Headline, Paragraph } from 'View/Common';
import { StyledModal } from '../Modal.style';
import { CustomScrollbar } from 'View/Component/CustomScrollbar/CustomScrollbar';
interface IProps {
  isOpen: boolean;
  toggle?: () => void;
}

export const HealthRequirementsModal: FunctionComponent<IProps> = ({ isOpen, toggle }) => {
  return (
    <StyledModal toggle={toggle} isOpen={isOpen} size="lg" headline={<Headline level={2}>Gesundheitsvorraussetzungen</Headline>}>
      <CustomScrollbar>
        <Paragraph>
          Die Teilnahme am 7Schläfer Präventionskurs erfolgt auf eigene Verantwortung. Ausschlusskriterien für eine Teilnahme sind schwere
          behandlungsbedürftige psychische Erkrankungen sowie behandlungsbedürftige Schlafstörungen.
        </Paragraph>
        <Paragraph>
          Der Kurs ist auf eine Teilnahme von Erwachsenen ab 18 Jahren ausgelegt, da ein gewisses Maß an Reflexionsfähigkeit und
          Selbstdisziplin erforderlich ist. Eine Teilnahme am Kurs „Erholsamen Schlaf finden“ erfolgt auf eigene Gefahr; eine Haftung wird
          nicht übernommen.
        </Paragraph>
        <Paragraph>
          Sollten während der Teilnahme schwere behandlungsbedürftige psychische Erkrankungen oder behandlungsbedürftige Schlafstörungen
          auftreten, sollte das Programm unterbrochen und lediglich nach Abklärung und in Begleitung von einer professionellen Unterstützung
          fortgesetzt werden.
        </Paragraph>
      </CustomScrollbar>
    </StyledModal>
  );
};
