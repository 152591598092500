import styled from 'styled-components';
import { asRem } from 'utils';
import { colors } from 'View/Theme';

export default styled.span`
  font-family: 'Source Sans Pro';
  color: ${colors.sleep.beige.c100};
  font-size: ${asRem(16)};
  font-weight: 300;
  letter-spacing: ${asRem(0.15)};
  line-height: ${asRem(20)};


  &.small {
    font-size: ${asRem(12)};
    a {
      font-size: ${asRem(12)};
      font-weight: 500;
    }
  }
`;
