import { ROUTES } from 'utils/typings/routes';
import { redirectToStoreDownload } from 'utils/redirectToStoreDownload';
import { StyledFooter } from './Footer.style';
import { Link } from '@reach/router';
import { Link as LinkMM, Paragraph, Span, Button, Row, Col, Image } from 'View/Common';
import { Section } from 'View/Container';
import appStoreBadge from 'img/app-store-badge.svg';
import playStoreBadge from 'img/google-play-badge.svg';
import { useGetInsuranceCompaniesQuery } from 'api/graphql';
import { useMemo } from 'react';

export const Footer = () => {
  const { data } = useGetInsuranceCompaniesQuery({
    variables: {
      isCooperationPartner: true,
    },
  });

  const activeCooperations = useMemo(() => Object.values(data?.insuranceCompanies || {}), [data?.insuranceCompanies]);

  if (!data) {
    return null;
  }

  return (
    <StyledFooter>
      <Section verticalPadding="small">
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 3 }}>
            <Paragraph className="small-bold">Dein 7Schläfer</Paragraph>
            <Link to={ROUTES.ZPP_LANDING_PAGE}>
              <Span className="small">Krankenkasse</Span>
            </Link>
            <Link to={ROUTES.TRUST_PORTAL}>
              <Span className="small">Sicherheits-Portal</Span>
            </Link>
            <Link to={ROUTES.CONTACT}>
              <Span className="small">Kontakt</Span>
            </Link>
          </Col>

          <Col xs={{ size: 12 }} md={{ size: 3 }}>
            <Paragraph className="small-bold">Partnerschaften</Paragraph>
            {activeCooperations.map((activeCooperation) => (
              <LinkMM href={activeCooperation.cooperationUrl || '#'} key={activeCooperation.cooperationUrl}>
                <Span className="small">{activeCooperation.name}</Span>
              </LinkMM>
            ))}
            <Link to={ROUTES.ZPP_LANDING_PAGE}>
              <Span className="small">Erstattungscheck</Span>
            </Link>
          </Col>

          <Col xs={{ size: 12 }} md={{ size: 3 }}>
            <Paragraph className="small-bold">Dein Zugang</Paragraph>
            <Link to={ROUTES.PRICING}>
              <Span className="small">Preise</Span>
            </Link>
            <Link to={ROUTES.TERMINATE}>
              <Span className="small">Verträge kündigen</Span>
            </Link>
          </Col>

          <Col xs={{ size: 12 }} md={{ size: 3 }}>
            <Paragraph className="small-bold">Hol dir die 7Schläfer-App</Paragraph>
            <Button onClick={redirectToStoreDownload}>App herunterladen</Button>
            <LinkMM href="https://apps.apple.com/de/app/7schl%C3%A4fer-die-schlaf-app/id1520389863">
              <Image className="store-badge" src={appStoreBadge} />
            </LinkMM>
            <LinkMM href="https://play.google.com/store/apps/details?id=com.greenhouse.schlafen&hl=gsw">
              <Image className="store-badge" src={playStoreBadge} />
            </LinkMM>
          </Col>
        </Row>
      </Section>

      <Section className="bottom-section" verticalPadding="none">
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 6 }}>
            <Paragraph className="small-bold">© 7Schläfer 2024</Paragraph>
          </Col>
          <Col xs={{ size: 12 }} md={{ size: 6 }} className="legal-links-section">
            <Link to={ROUTES.IMPRINT}>
              <Span className="small">Impressum</Span>
            </Link>
            <Link to={ROUTES.TERMS}>
              <Span className="small">AGB</Span>
            </Link>
            <Link to={ROUTES.PRIVACY}>
              <Span className="small">Datenschutzhinweise</Span>
            </Link>
          </Col>
        </Row>
      </Section>
    </StyledFooter>
  );
};
