import { Caption, Col, Headline, Paragraph, Row, Sup, Toggle, Link, Button } from 'View/Common';
import { DarkContentBox } from 'View/Component';
import { LoadingOutlined } from '@ant-design/icons';
import React, { FC, MouseEvent, useState } from 'react';
import { useGetOffers } from 'View/HookApi/useGetOffers';
import { MarkdownModal } from 'View/Component/Modals';
import { StaticPageName } from 'api/graphql';
import { useSelectOffer } from 'View/HookApi/useSelectOffer';

type TProps = {
  setShowHealthRequirements: (show: boolean) => void;
  setShowAccessibilityModal: (show: boolean) => void;
};

export const HealthInsuranceFormNoCooperation: FC<TProps> = ({ setShowHealthRequirements, setShowAccessibilityModal }) => {
  const { loading, offers } = useGetOffers();

  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
  const [showTermsError, setShowTermsError] = useState<boolean>(false);
  const [showPrivacyError, setShowPrivacyError] = useState<boolean>(false);

  const { selectOffer } = useSelectOffer();

  const handleOnSetPrivacy = (privacyAccepted: boolean) => {
    setPrivacyAccepted(privacyAccepted);
    setShowPrivacyError(false);
  };

  const handleOnSetTerms = (termsAccepted: boolean) => {
    setTermsAccepted(termsAccepted);
    setShowTermsError(false);
  };

  const handleOnOpenZppOffer = (evt: MouseEvent) => {
    if (!offers.zpp) {
      return;
    }
    if (!termsAccepted || !privacyAccepted) {
      if (!termsAccepted) {
        setShowTermsError(true);
      }
      if (!privacyAccepted) {
        setShowPrivacyError(true);
      }
      return;
    }

    selectOffer(evt, offers.zpp);
  };

  return (
    <>
      <MarkdownModal isOpen={showTerms} toggle={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions} />
      <MarkdownModal isOpen={showPrivacy} toggle={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy} />
      <DarkContentBox className="content-form-box center">
        <Headline level={4}>Präventionskurs</Headline>
        <Caption>inkl. 12 Monate 7Schläfer</Caption>
        <Paragraph className="price-field">{offers.zpp?.price?.localizedPrice || <LoadingOutlined />}</Paragraph>
        <Caption>
          einmalig<Sup>*</Sup>
        </Caption>
        <br />
        <br />
        <Row>
          <Col xs={2} md={2}>
            <Toggle onChange={handleOnSetPrivacy} title="Gesundheitsvoraussetzungen bestätigen" />
          </Col>
          <Col xs={10} md={9} className="text-left">
            <Caption>
              Ich akzeptiere die{' '}
              <Link title="öffnet ein Fenster mit dem gesundheitlichen Voraussetzungen" onClick={() => setShowHealthRequirements(true)}>
                Gesundheits&shy;voraussetzungen
              </Link>{' '}
              und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.
              {showPrivacyError && <span className="error">Bitte akzeptiere die Gesundheitsvoraussetzungen.</span>}
            </Caption>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={2} md={2}>
            <Toggle onChange={handleOnSetTerms} title="AGB und Datenschutz bestätigen" />
          </Col>
          <Col xs={10} md={9} className="text-left">
            <Caption dataCyName="terms-privacy-caption">
              Ich akzeptiere die{' '}
              <Link title="öffnet ein Fenster mit den AGBs" onClick={() => setShowTerms(true)}>
                AGB
              </Link>{' '}
              und die Hinweise zum{' '}
              <Link title="öffnet ein Fenster mit den Datenschutzbedingungen" onClick={() => setShowPrivacy(true)}>
                Datenschutz
              </Link>
              .{showTermsError && <span className="error">Bitte akzeptiere die AGB und Datenschutz.</span>}
            </Caption>
          </Col>
        </Row>
        <br />
        <Button title="Präventionskurs kaufen" onClick={handleOnOpenZppOffer} className="buy-button" disabled={loading}>
          {loading && <LoadingOutlined />}
          Jetzt kaufen
        </Button>
        <br />
        <Caption small className="info-text">
          * Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS
          13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind
          berücksichtigt. Zu den{' '}
          <Link title="öffnet ein Fenster mit den Angaben zur Barrierefreiheit" onClick={() => setShowAccessibilityModal(true)}>
            Angaben zur Barrierefreiheit
          </Link>
        </Caption>
      </DarkContentBox>
    </>
  );
};
