import { Link, navigate } from '@reach/router';
import React, { ChangeEvent, FormEvent, useContext, useState, useEffect } from 'react';
import { useRedeemVoucherMutation } from 'api/graphql';
import { LoadingSpinner, Span } from 'View/Common';
import { ROUTES } from 'utils/typings/routes';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import { Store } from 'utils/store';
import { logError } from 'utils/logError';

interface Props {
  data: {
    headlineVoucher: string;
    sublineVoucher: string;
    placeholder: string;
    infoResendToken: string;
  };
}

const VoucherForm: React.FC<Props> = ({ data }) => {
  const [, dispatch] = useContext(Store);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(true);
  const { headlineVoucher, sublineVoucher, placeholder, infoResendToken } = data;

  const [redeemVoucher, { loading }] = useRedeemVoucherMutation({
    onCompleted: (data) => {
      setIsCodeValid(true);
      navigate(ROUTES.VOUCHER_SUCCESS);

      const token = data?.redeemVoucher?.token;
      dispatch({
        type: ACTION_TYPES.UPDATE_REDEEM_VOUCHER,
        redeemVoucher: { token },
      });
    },

    onError: (error) => {
      setIsCodeValid(false);
      logError(error);
    },
  });

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.CLEAN_UP_REDEEM_VOUCHER,
    });
  }, [dispatch]);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    redeemVoucher({ variables: { code, email } });
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value, id } = event.target;
    if (id === 'code') {
      setCode(value);
    }
    if (id === 'email') {
      setEmail(value);
    }
  };

  return (
    <>
      <div className="voucher-form__section ta-center align-items-center margin-top-75 margin-bottom-24">
        <h2 className="voucher-form__heading heading-1-b2b margin-bottom-8">{headlineVoucher}</h2>
        <p className="voucher-form__paragraph margin-bottom-20-40 ">{sublineVoucher}</p>

        <form onSubmit={handleSubmit} className="voucher-form__form">
          <div className="voucher-form__wrapper-input ta-left flex-column justify-c-start margin-bottom-20-40 ">
            <label className="visuallyhidden" htmlFor="code">
              {placeholder}
            </label>
            <input
              placeholder={placeholder.toLocaleUpperCase()}
              type="text"
              onChange={handleChange}
              value={code}
              id="code"
              className="voucher-form__input"
              required
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="on"
            />
            {!isCodeValid && <div className="voucher-form__invalid-feedback align-self-flex-start">{placeholder}&nbsp;ungültig</div>}
          </div>

          <div className="voucher-form__wrapper-input ta-left flex-column justify-c-start margin-bottom-10-20">
            <label className="visuallyhidden" htmlFor="email">
              E-Mail-Adresse
            </label>
            <input
              placeholder="E-Mail-Adresse*"
              type="email"
              onChange={handleChange}
              value={email}
              id="email"
              className="voucher-form__input"
              required
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="email"
            />
          </div>
          <p className="voucher-form__subtext align-self-center margin-bottom-30-45">
            *An diese E-Mail senden wir deinen Freischalt-Link. Bitte stelle sicher, dass du die Mail auch erhalten kannst!
          </p>
          <button className="voucher-form__cta-btn align-self-center " type="submit" disabled={loading} title="Gutschein einlösen Button">
            {loading ? <LoadingSpinner /> : 'Gutschein einlösen'}
          </button>
        </form>
        <p className="voucher-form__paragraph">{infoResendToken}</p>
        <Link className="voucher-form__resend-link" to={ROUTES.RESEND_TOKEN_B2B} title="Neuen Freischalt-Link anfordern">
          Neuen Freischalt-Link anfordern 
        </Link>
      </div>
    </>
  );
};

export default VoucherForm;
