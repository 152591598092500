import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IInsuranceCooperationState } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container/InsuranceContainer/InsuranceContainerContext';

export const attendInsuranceCooperationUserDataRequest = (
  state: Draft<IInsuranceCooperationState>,
  { type }: PayloadAction<{ token: string }>,
) => {
  state.openRequests = [...state.openRequests, type];
  state.failedRequests = state.failedRequests.filter(
    (actionType) => actionType !== 'insuranceCooperation/attendInsuranceCooperationUserDataFailure',
  );
  state.updatedAt = Date.now();
  state.wrongOtp = false;
  state.errorCode = null;
};

export const attendInsuranceCooperationUserDataSuccess = (state: Draft<IInsuranceCooperationState>) => {
  state.updatedAt = Date.now();
  state.openRequests = state.openRequests.filter(
    (actionType) => actionType !== 'insuranceCooperation/attendInsuranceCooperationUserDataRequest',
  );
  state.showFormScreen = INSURANCE_CONTAINER_VIEW.SUCCESS;
};

export const attendInsuranceCooperationUserDataFailure = (
  state: Draft<IInsuranceCooperationState>,
  { type, payload }: PayloadAction<{ error: any }>,
) => {
  state.updatedAt = Date.now();
  state.failedRequests = [...state.failedRequests, type];
  state.openRequests = state.openRequests.filter(
    (actionType) => actionType !== 'insuranceCooperation/attendInsuranceCooperationUserDataRequest',
  );
  state.errorCode = payload.error.graphQLErrors[0]?.code || 'UNKNOWN_ERROR';

  if (`${payload?.error?.networkError?.statusCode || ''}` === '401') {
    state.wrongOtp = true;
  }
};
