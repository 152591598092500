import { FunctionComponent } from 'react';
import { BgColorContainer } from 'View/Component';
import { Col, Headline, Image, Paragraph, Row, Button } from 'View/Common';
import ZppAppSectionBackground from 'img/ZppAppSectionBackground';
import ZppApp from 'img/ZppAppImage';
import { Section } from 'View/Container';

interface IProps {
  startNowButton?: () => void;
}

export const AppDescriptionSection: FunctionComponent<IProps> = ({ startNowButton }) => {
  return (
    <BgColorContainer>
      <Section className="app-description-section">
        <Row>
          <Col xs={12} md={7} className="text-left">
            <Headline level={2}>
              Mit dem speziell konzipierten Kurs "Erholsamen Schlaf finden – Ein App-gestützter Online-Kurs" sorgst du für mehr Gesundheit –
              bei Tag und bei Nacht.
            </Headline>
            <Paragraph>
              In 7 Kurswochen teilt 7Schläfer Schlafexperte Dr. Albrecht Vorster sein Wissen mit dir. Du erfährst, wie du erholsamen Schlaf
              mit gesunden Gewohnheiten und wirksamen Routinen förderst. Die kurzen Einheiten sind einfach verständlich und du kannst die
              Tipps und Tricks sofort in deinem Alltag umsetzen.
            </Paragraph>
            <Paragraph>
              7Schläfer kooperiert mit gesetzlichen Krankenkassen. Je nach Krankenkasse können bis zu 100% der Kosten für 7Schläfer
              erstattet werden.
            </Paragraph>
            <Button onClick={startNowButton} title="Gehe zum Registrierformular">
              Jetzt loslegen
            </Button>
          </Col>
          <Col xs={12} md={{ offset: 0, size: 5 }} lg={{ offset: 1, size: 3 }}>
            <Image image={ZppApp.src} srcSet={ZppApp.srcSet} className="mx-auto d-block phone" alt="IPhone mit Bild der 7 Schläferapp" />
          </Col>
        </Row>
        <Image
          className="app-section-background-plane"
          image={ZppAppSectionBackground.src}
          srcSet={ZppAppSectionBackground.srcSet}
          alt="Papierflieger im Weltall"
        />
      </Section>
    </BgColorContainer>
  );
};
