import { colors } from 'View/Theme';
import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import waveMobile from '../../img/bgr_waves_mobile.svg';
import waveDesktop from '../../img/bgr_waves.svg';

export default styled.main`
  position: relative;
  background-color: ${colors.sleep.blue.c900} !important;
  background-image: url(${waveMobile});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 230px);
  width: 100%;

  ${mediaQueries.md} {
    background-image: url(${waveDesktop});
  }

  .health-cooperation-slider {
    background-color: ${colors.sleep.blue.c800} !important;
  }
`;
