import React, { FC, useContext } from 'react';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container';
import { Paragraph, Headline, Link } from 'View/Common';
import { isMobile } from 'react-device-detect';
import StoreButtons from 'components/StoreButtons';


export type TSuccessViewProps = {
  children?: React.ReactNode;
  className?: string;
};

export const SuccessView: FC<TSuccessViewProps> = ({ children, className = '' }) => {
  const { activeView, b2bClientKey } = useContext(InsuranceContainerContext);

  if (activeView !== INSURANCE_CONTAINER_VIEW.SUCCESS) {
    return null;
  }

  return (
    <div className="success-view" id="insurance-cooperation-success-view">
      <div className="form-header">
        <Headline level={3}>Super, das hat geklappt!</Headline>
        <Paragraph>
          Öffne jetzt die 7Schläfer-App und melde dich dort mit der hier angegebenen E-Mail Adresse an. Für die Anmeldung in der App erhälst
          du erneut einen Einmalcode zur Anmeldung.
        </Paragraph>
      </div>
      {isMobile ? (
        <Link to="schlafen://open" className="mt-4">
          App öffnen
        </Link>
      ) : (
        <StoreButtons />
      )}
      {children}
    </div>
  );
};
