import { colors } from 'View/Theme';
import styled from 'styled-components';
import { asRem } from 'utils';
import { RoundedBox } from 'View/Common';

export const StyledRoundedBox = styled(RoundedBox)`
  background-color: ${colors.sleep.blue.c600};
  border: solid ${asRem(1)} ${colors.sleep.blue.c300};
  border-radius: ${asRem(8)};
  padding: ${asRem(16)};
  display: flex;
  flex-direction: column;

  .card-head {
    width: calc(100% + ${asRem(64)});
    background-color: ${colors.sleep.blue.c300};
    padding: ${asRem(18)} ${asRem(16)} ${asRem(4)};
    margin: -${asRem(24)} -${asRem(32)} ${asRem(16)};
    text-align: left;
  }

  p {
    margin: 0 0 ${asRem(15)};
  }
`;
