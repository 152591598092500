import { RouteComponentProps } from '@reach/router';
import React, { useRef } from 'react';

import { HintBox, IconBox, PartnerLogo } from 'View/Component';
import Header from 'components/Header';
import IntroB2B from 'components/B2B/IntroB2B';
import ExplanationCode from 'components/B2B/ExplanationCode';
import Benefits from 'components/B2B/Benefits';
import VoucherForm from 'components/B2B/VoucherForm';
import { Footer_Branding } from 'View/Component';
import { Link } from 'View/Common';

import steps1 from 'img/icon_01.svg';
import steps2 from 'img/icon_02.svg';
import steps3 from 'img/icon_03.svg';
import steps4 from 'img/icon_04.svg';

const dataIntro = {
  headlinePartOne: 'Einfach besser schlafen.',
  headlinePartTwo: 'Mit der Gothaer zur richtigen Schlafstrategie.',
  imgClassName: 'intro_b2b__hero-image-xing-premium',
  altText: 'Bild: Luna schläft auf dem Halbmond',
  subline: 'Für Gothaer Versicherte ist der Zugang für 12 Monate kostenlos.',
};

const dataExplanationCode = {
  claim: 'Für Versicherte der Gothaer ist der gesamte Premium Inhalt von 7Schläfer für 12 Monate kostenlos.',
  duration: '12 Monate',
};

const dataCode = {
  text1: 'Deinen persönlichen Zugangs-Code zu 7Schläfer erhältst du direkt in der ',
  text2: 'Gothaer Gesundheitsapp',
  text3: ' auf der 7Schläfer Seite.',
};

const dataVoucherForm = {
  headlineVoucher: 'Zugangs-Code eingeben',
  sublineVoucher: 'Du hast einen Zugangs-Code von der Gothaer für 7Schläfer bekommen. Hier kannst du ihn einlösen.',
  placeholder: 'Zugangs-Code',
  infoResendToken: 'Du hast deinen Zugangs-Code schon hier eingelöst, aber keinen Freischalt-Link erhalten?',
};

const dataHowToContinueIconBox = {
  headline: 'Wie funktioniert das?',
  items: [
    {
      text: 'Nach dem Einlösen deines Codes bekommst Du einen Freischalt-Link an die hier angegebene E-Mail Adresse zugeschickt.',
      icon: steps1,
    },
    {
      text: 'Lade die App aus dem Appstore und registriere dich, falls du es nicht bereits hast.',
      icon: steps2,
    },
    {
      text: 'Öffne den Freischalt-Link aus der E-Mail auf deinem Mobiltelefon.',
      icon: steps3,
    },
    {
      text: 'Alle Inhalte des 7Schläfer Programms werden in der App für dich freigeschaltet.',
      icon: steps4,
    },
  ],
};

const GothaerLandingpage: React.FC<RouteComponentProps> = (props) => {
  const gothaerVoucherRef = useRef<HTMLDivElement>(null);
  const handleRefClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    gothaerVoucherRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const { path } = props;
  return (
    <>
      <Header noNavigation path={path} />
      <main className="main padding-l-0 padding-r-0">
        <IntroB2B refClick={handleRefClick} data={dataIntro} path={path} />
        <div className="main__wrapper--blue-bg padding-l-0 padding-r-0">
          <Benefits />
          <ExplanationCode refClick={handleRefClick} data={dataExplanationCode}>
            {dataCode.text1}
            <>
              &nbsp;
              <Link
                href="https://www.gothaer.de/meine-gothaer/informationen-ratgeber/informationen-fuer-krankenversicherte/gesundheitsapp.htm"
                title="Informationsseite der Gothaer öffnen"
              >
                {dataCode.text2}
              </Link>
              &nbsp;
            </>
            {dataCode.text3}
          </ExplanationCode>
        </div>
        <Footer_Branding isDarkBlue />
        {/* ID hier hat schönere Position zum hinscrollen */}
        <div ref={gothaerVoucherRef} id="voucher-form"></div>
        <VoucherForm data={dataVoucherForm} />
        <IconBox headline={dataHowToContinueIconBox.headline} items={dataHowToContinueIconBox.items}>
          <HintBox />
        </IconBox>
        <PartnerLogo />
      </main>
    </>
  );
};

export default GothaerLandingpage;
