import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { Section } from 'View/Container';
import { asRem } from 'utils/asRem';

export const StyledSection = styled(Section)`
  margin: ${asRem(50)} 0 0;
  padding-bottom: 0;
  position: relative;

  .luna-wrapper {
    display: flex;
    justify-content: center;

    ${mediaQueries.md} {
      justify-content: flex-start;
    }
  }

  .image-wrapper {
    ${mediaQueries.md} {
      justify-content: flex-end;
      display: flex;
    }
  }

  .pfeile-image {
    display: none;
    align-self: flex-end;

    ${mediaQueries.md} {
      align-self: flex-end;
      display: block;
    }
  }
`;
