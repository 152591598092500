import { RouteComponentProps } from '@reach/router';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useResendPurchaseTokenMutation } from 'api/graphql';
import Header from 'components/Header';
import { Button, LoadingSpinner } from 'View/Common';
import { ROUTES } from 'utils/typings/routes';
import { ParamType } from 'utils/typings/urlParamType';
import URLParam from 'utils/urlParameter';

import ResendSuccess from './ResendSuccess';

const ResendToken: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState('');
  const { path } = props;
  const [noNavigation, setNoNavigation] = useState(false);

  const [resendSuccess, setResendSuccess] = useState(false);
  const [resenPurchaseToken, { loading }] = useResendPurchaseTokenMutation({
    onCompleted: () => setResendSuccess(true),
  });

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const { value } = event.target;
    setEmail(value);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    resenPurchaseToken({ variables: { email } });
  }

  useEffect(() => {
    URLParam(window.location, ParamType.EMAIL) && setEmail(URLParam(window.location, ParamType.EMAIL));
    props.path === ROUTES.RESEND_TOKEN_B2B ? setNoNavigation(true) : setNoNavigation(false);
  }, [props.path]);

  return (
    <>
      <Header noNavigation={noNavigation} path={path} />
      <main
        className="main resend
      "
      >
        <section id="resend_token" className="resend__wrapper__flex-col margin-top-120 margin-bottom-100">
          <h1 className="heading heading-1 margin-bottom-8">Neuen Freischalt-Link anfordern</h1>
          {!resendSuccess && (
            <p className="paragraph paragraph__text-center">
              Du hast einen Kauf bei 7Schläfer abgeschlossen oder einen Code eingelöst, aber keinen Freischalt-Link erhalten?
            </p>
          )}
          <div className="resend__box">
            {!resendSuccess && (
              <div className="wrapper flex-column">
                <p className="resend__paragraph">
                  Du kannst hier ganz einfach einen neuen Freischalt-Link anfordern. Gib dafür die E-Mail Adresse, die du beim Kauf der
                  7Schläfer App oder beim Einlösen eines Codes verwendet hast, in das Feld unten ein und klicke auf den
                  "Link-anfordern"-Button. <br />
                  Bei Angabe einer zum Kauf oder bei einer Einlösung verwendeten E-Mail Adresse, senden wir einen neuen Freischalt-Link an
                  diese E-Mail Adresse. Falls du deine E-Mail Adresse nicht mehr weißt oder dein Link aus anderen Gründen nicht ankommt,
                  schreib uns bitte eine E-Mail.
                </p>
                <form onSubmit={(event) => handleSubmit(event)}>
                  <div className="coupon_code">
                    <div className="wrapper__input margin-top-50">
                      <label className="visuallyhidden" htmlFor="resend_email">
                        E-Mail-Adresse
                      </label>
                      <input
                        className="resend__input"
                        onChange={(event) => handleInputChange(event)}
                        placeholder="E-Mail-Adresse"
                        type="email"
                        id="resendEmail"
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="off"
                        required
                        value={email}
                      />
                      <Button
                        htmlType="submit"
                        type="secondary"
                        className="coupon_button"
                        disabled={loading}
                        title="Einen neuen Freischalt-Link anfordern"
                      >
                        {loading ? <LoadingSpinner /> : 'Link anfordern'}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {resendSuccess && <ResendSuccess />}
          </div>
        </section>
      </main>
    </>
  );
};

export default ResendToken;
