import { colors } from '@missionme/mime_elements';
import React, { FunctionComponent, CSSProperties } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { asRem } from 'utils';

interface IProps {
  height?: number;
  children: React.ReactNode;
  thumbYProps?: { style?: CSSProperties };
  trackYProps?: { style?: CSSProperties };
}

export const CustomScrollbar: FunctionComponent<IProps> = ({ height = '80vh', children, thumbYProps, trackYProps }) => {
  return (
    <Scrollbar
      noScrollX={true}
      style={{ width: '100%', height, minHeight: '80vh' }}
      thumbYProps={{
        style: {
          background: colors.sleep.beige.c100,
          borderRadius: asRem(10),
          height: asRem(30),
          ...(thumbYProps?.style || {}),
        },
      }}
      trackYProps={{
        style: {
          background: 'transparent',
          borderRadius: asRem(10),
          position: 'absolute',
          right: asRem(-22),
          marginTop: asRem(40),
          paddingBottom: asRem(40),
          ...(trackYProps?.style || {}), // Falls Custom Props vorhanden, überschreiben sie die Defaults
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};
