import StoreButtons from 'components/StoreButtons';
import { redirectToStoreDownload } from 'utils/redirectToStoreDownload';
import { Button } from 'View/Common';
import { StyledHomeSection } from './HomeSection.style';

const Section_1 = () => {
  return (
    <StyledHomeSection>
      <div className="home__bgr-top-section">
        <div className="wrapper padding-top-100">
          <section className="home__section-1">
            <div className="home__image-luna-moon">
              <span className="visuallyhidden">Bild: Luna schläft auf dem Halbmond</span>
            </div>
            <div className="home__cta-container">
              <h1 className="heading heading-1">Werde mit uns zum 7Schläfer!</h1>
              <Button
                className="cta-button"
                title="Öffnet den entsprechenden Store zum Herunterladen der App"
                onClick={redirectToStoreDownload}
              >
                JETZT HERUNTERLADEN
              </Button>
              <StoreButtons />
            </div>
          </section>
        </div>
      </div>
    </StyledHomeSection>
  );
};

export default Section_1;
