import { Link } from 'View/Common';
import React, { useState, MouseEvent, useMemo } from 'react';
import { MarkdownModal } from 'View/Component/Modals';
import { StaticPageName } from 'api/graphql';

export const LegalConditionLabel = () => {
  const [showModal, setShowModal] = useState<string | false>(false);

  const handleOnClick = (modal: string) => (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    setShowModal(modal);
  };

  const pageName = useMemo(() => {
    switch (showModal) {
      case 'showTerms':
        return StaticPageName.TermsAndConditions;
      case 'showPrivacy':
        return StaticPageName.PrivacyPolicy;
      // no-default
    }
    return StaticPageName.PrivacyPolicy;
  }, [showModal]);

  return (
    <>
      <MarkdownModal isOpen={!!showModal} toggle={() => setShowModal(false)} pageName={pageName} />
      Ich akzeptiere die{' '}
      <Link
        title="öffnet ein Fenster mit den AGBs"
        className="form-regulatory-link"
        onClick={handleOnClick('showTerms')}
        data-cy-name="terms-privacy-caption"
      >
        AGB
      </Link>{' '}
      und die Hinweise zum{' '}
      <Link
        title="öffnet ein Fenster mit den Datenschutzbedingungen"
        className="form-regulatory-link"
        onClick={handleOnClick('showPrivacy')}
        data-cy-name="terms-privacy-caption"
      >
        Datenschutz
      </Link>
      .
    </>
  );
};
