import Luna from 'img/luna_refundable';
import Pfeile from 'img/arrows_koop';
import { StyledSection } from './Section_Cooperation.style';
import { ROUTES } from 'utils/typings/routes';
import { Image, Headline, Row, Col, Button, Paragraph } from 'View/Common';

export const Section_Cooperations = () => {
  return (
    <StyledSection backgroundColor="c800">
      <Row>
        <Col xs={12} md={6} lg={5} className="offset-lg-1 offset-md-0">
          <Headline level={2}>Deine Krankenkasse zahlt!</Headline>
          <Paragraph>
            Verbessere deinen Schlaf mit 7Schläfer. Deine Krankenkasse übernimmt jetzt 75-100% der Kosten für unseren Präventionskurs. Jetzt
            prüfen, wie hoch deine Erstattung ist!
          </Paragraph>
          <Button target="_self" href={`${ROUTES.ZPP_LANDING_PAGE}#erstattungscheck-box`}>
            ZUM ERSTATTUNGSCHECK
          </Button>
          <Col className="image-wrapper">
            <Image image={Pfeile.src} srcSet={Pfeile.srcSet} alt="Pfeile" className="pfeile-image" />
          </Col>
        </Col>
        <Col xs={12} md={6} lg={5}>
          <Col className="luna-wrapper">
            <Image image={Luna.src} srcSet={Luna.srcSet} alt="Luna schläft" />
          </Col>

          <Headline level={2}>Starte ohne Vorauszahlung</Headline>
          <Paragraph>
            Wenn du bei einer unserer Kooperations-Krankenkassen versichert bist, kannst du den Präventionskurs sogar direkt starten, ganz
            ohne Vorauszahlung. Einfach auf das Logo deiner Krankenkasse klicken und mehr erfahren.
          </Paragraph>
        </Col>
      </Row>
    </StyledSection>
  );
};
