import { Headline } from 'View/Common';
import { HoverImageLink, Slider } from 'View/Component';
import { FC, useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CooperationStyle } from './HealthCooperations.style';
import { ISliderSettings, useSliderSettings } from 'View/HookApi/useSliderSettings';
import { useGetInsuranceCompaniesQuery } from 'api/graphql';

type THealthCooperationsProps = {
  id: string;
};

export const HealthCooperations: FC<THealthCooperationsProps> = ({ id }) => {
  const [totalLogoCount, setTotalLogoCount] = useState(0);

  const { loading, data } = useGetInsuranceCompaniesQuery({
    variables: {
      isCooperationPartner: true,
    },
  });
  const { insuranceCompanies } = data || {};

  useEffect(() => {
    setTotalLogoCount(insuranceCompanies?.length ?? 0);
  }, [insuranceCompanies]);

  const settings: ISliderSettings = useSliderSettings(totalLogoCount);

  if (!insuranceCompanies || totalLogoCount === 0) {
    return;
  }

  return (
    <CooperationStyle id={id} className="text-center" verticalPadding={{ sm: 0, md: 30, lg: 40 }} type="full-width">
      {totalLogoCount === 1 ? (
        <Headline level={3}>Unser Kooperationspartner</Headline>
      ) : (
        <Headline level={3}>Unsere Kooperationen</Headline>
      )}

      {totalLogoCount <= 2 ? (
        <div className="wrapper">
          {insuranceCompanies.map((cooperation) =>
            cooperation.cooperationLogo && cooperation.cooperationLogoHover ? (
              <HoverImageLink
                key={cooperation.cooperationUrl}
                image={cooperation.cooperationLogo}
                hoverImage={cooperation.cooperationLogoHover}
                href={cooperation.cooperationUrl}
                className="logo"
              />
            ) : null,
          )}
        </div>
      ) : (
        <Slider key={settings.initialSlide} settings={settings}>
          {insuranceCompanies.map((cooperation) =>
            cooperation.cooperationLogo && cooperation.cooperationLogoHover ? (
              <HoverImageLink
                key={cooperation.cooperationUrl}
                image={cooperation.cooperationLogo}
                hoverImage={cooperation.cooperationLogoHover}
                href={cooperation.cooperationUrl}
                className="logo"
              />
            ) : null,
          )}
        </Slider>
      )}
    </CooperationStyle>
  );
};
