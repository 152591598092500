import styled from 'styled-components';
import { Select } from 'antd';
import { asRem } from 'utils';
import dropDownArrow from './nav-arrow-down.svg';
import { colors } from 'View/Theme';

export default styled(Select)`
  width: 100%;
  border-radius: ${asRem(8)};
  height: ${asRem(50)};
  border: solid ${asRem(1)} ${colors.sleep.blue.c300};
  background-color: ${colors.sleep.blue.c600};
  color: ${colors.sleep.beige.c100};
  font-family: 'Source Sans Pro';

  &.ant-select {
    .ant-select-selector input.ant-select-selection-search-input,
    .ant-select-selector {
      height: 100%;
      border: none;
      background-color: transparent;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-selection-search-input {
      font-family: 'Source Sans Pro';
      text-transform: uppercase;
      font-weight: 300;
      font-size: ${asRem(16)};
      letter-spacing: ${asRem(0.15)};
      line-height: ${asRem(46)};
      color: ${colors.sleep.beige.c100} !important;
      opacity: 1;
    }

    .ant-select-arrow .anticon {
      color: ${colors.sleep.beige.c100};
    }

    .ant-select-arrow {
      & > span[aria-label='down'] {
        position: relative;

        &:after {
          position: absolute;
          content: '';
          left: ${asRem(-24)};
          top: ${asRem(-12)};
          width: ${asRem(24)};
          height: ${asRem(24)};
          background: url(${dropDownArrow}) center center no-repeat;
          background-size: contain;
        }

        svg {
          display: none;
        }
      }
    }
  }
`;

export const dropdownStyle = {
  backgroundColor: colors.sleep.blue.c600,
};
