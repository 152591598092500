import React, { forwardRef, FunctionComponent, useState } from 'react';
import { Col, Image, Row } from 'View/Common';
import {
  AccessibilityModal,
  HealthInsuranceFormCooperation,
  HealthInsuranceSelectionBox,
  HealthRequirementsModal,
  IHealthInsuranceEntry,
} from 'View/Component';
import ZppShipBackground from 'img/ZppShipBackground';
import { MarkdownModal } from 'View/Component/Modals/MarkdownModal/MarkdownModal';
import { StaticPageName } from 'api/graphql';
import { HealthInsuranceFormNoCooperation } from 'View/Component';
import { Section } from 'View/Container';

interface IProps {
  ref?: React.LegacyRef<HTMLDivElement>;
}
export const RefundCheckFormSection: FunctionComponent<IProps> = forwardRef((_, ref) => {
  const [healthInsurance, setHealthInsurance] = useState<IHealthInsuranceEntry | null>(null);
  const [showHealthRequirements, setShowHealthRequirements] = useState<boolean>(false);
  const [showAccessibilityModal, setShowAccessibilityModal] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

  return (
    <div id="erstattungscheck-box">
      <HealthRequirementsModal isOpen={showHealthRequirements} toggle={() => setShowHealthRequirements(false)} />
      <AccessibilityModal isOpen={showAccessibilityModal}  toggle={() => setShowAccessibilityModal(false)} />
      <MarkdownModal isOpen={showTerms} toggle={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions} />
      <MarkdownModal isOpen={showPrivacy} toggle={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy} />
      <div ref={ref}>
        <Section className="refund-check-form-section">
          <Image
            className="app-section-background"
            image={ZppShipBackground.src}
            srcSet={ZppShipBackground.srcSet}
            alt="Papierboot mit Angelleine"
          />
          <Row>
            <Col xs={12} md={6} className="form-area">
              <HealthInsuranceSelectionBox healthInsurance={healthInsurance} onChange={setHealthInsurance} />
            </Col>
            <Col xs={12} md={{ size: 5, offset: 1 }} className="price-area">
              <div className="flex">
                {healthInsurance?.cooperationPartner ? (
                  <HealthInsuranceFormCooperation healthInsurance={healthInsurance} setShowAccessibilityModal={setShowAccessibilityModal} />
                ) : (
                  <HealthInsuranceFormNoCooperation
                    setShowHealthRequirements={setShowHealthRequirements}
                    setShowAccessibilityModal={setShowAccessibilityModal}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Section>
      </div>
    </div>
  );
});
