import { colors } from 'View/Theme';
import { asRem } from 'utils';
import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';

export const StyledFooter = styled.div`
  background-color: ${colors.sleep.blue.c900};
  display: flex;
  flex-direction: column;
  margin-top: ${asRem(-2)};

  a {
    display: block;
    text-decoration: underline;

    span {
      margin: ${asRem(16)} 0 !important;
    }
  }

  a.link:hover,
  a.link:active,
  a:hover,
  a:active,
  a:hover span,
  a:active span {
    color: ${colors.sleep.blue.c100} !important;
    filter: brightness(100%) !important;
    opacity: 1 !important;
  }

  .store-badge {
    display: block;
    height: ${asRem(30)};
    margin: ${asRem(16)} 0;
  }

  button {
    font-size: ${asRem(16)} !important;

    ${mediaQueries.md} {
      font-size: ${asRem(13)} !important;
    }

    ${mediaQueries.lg} {
      font-size: ${asRem(16)} !important;
    }
  }

  .bottom-section {
    z-index: 1;
    background-color: ${colors.sleep.blue.c700} !important;
    display: flex;

    .small-bold {
      margin-bottom: 0 !important;
    }
  }

  .legal-links-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${mediaQueries.md} {
      justify-content: flex-end;
    }

    a {
      margin: 0 0 0 ${asRem(16)};
    }
  }
`;
