import styled from 'styled-components';
import { asRem } from 'utils';
import { Modal } from 'View/Component';

export const StyledModal = styled(Modal)`
  .icon-wrapper svg {
    display: none !important;
  }

  .icon-wrapper i::after {
    content: '✕' !important;
    font-size: ${asRem(30)}!important;
    font-weight: bold !important;
    font-style: normal !important;
    color: beige !important;
    position: absolute !important;
    right: ${asRem(17)}!important;
    cursor: pointer !important;
  }
`;
